<template>
  <DxPopup v-model:visible="visible" width="800px" :drag-enabled="true" :resizeEnabled="true" :hide-on-outside-click="true" :show-close-button="true" :show-title="true" :shading="true" title="Odeslat zprávu">
    <DxToolbarItem widget="dxButton" toolbar="bottom" location="before" :options="{ text: 'Odeslat', onClick: onSend }" />
    <div>
      <div v-if="message && visible">
        <DxForm labelMode="floating" :form-data="message.data" validation-group="message">
          <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />

          <DxItem data-field="recipient" :isRequired="true" editor-type="dxSelectBox" :editor-options="{searchEnabled: true, items: recipients, displayExpr: recipientDisplayExpr, valueExpr: 'uid'}">
            <DxLabel text="Příjemce" />
          </DxItem>

          <DxItem data-field="subject" :isRequired="true" :editor-options="{ maxLength: 200 }">
            <DxLabel text="Předmět" />
          </DxItem>

          <DxItem :colSpan="2" data-field="text" :isRequired="false" editor-type="dxTextArea" :editor-options="{ height: 90, maxLength: 10000 }">
            <DxLabel text="Text" />
          </DxItem>

        </DxForm>

        <DxForm labelMode="floating" v-if="message && message.additionalFields" :items="message.additionalFields" :form-data="message.additionalData" validation-group="message">
          <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />
        </DxForm>

        <DxFileUploader :chunk-size="200000" name="file" :upload-url="uploadUrl" :multiple="true" @uploaded="onUploaded" @beforeSend="onBeforeSend" />
      </div>
    </div>
  </DxPopup>
</template>

<script src="./send-message.ts"></script>
<style scoped lang="scss" src="./send-message.scss"></style>