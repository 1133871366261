//require(`@/css/${process.env.VUE_APP_STYLE}.scss`);
import '@/css/dev.scss';
import emitter from "@/services/emitter";
import '@fortawesome/fontawesome-pro/css/all.css';
import axios from 'axios';
import 'bootstrap';
import { loadMessages, locale } from "devextreme/localization";
import csMessages from "devextreme/localization/messages/cs.json";
import moment from 'moment';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import { VueMasonryPlugin } from "vue-masonry";
import App from './app.vue';
import i18n from './i18n';
import configure from './services/config';
import router from './services/router';

export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

let app = createApp(App)
  .use(i18n)
  .use(router)
  .use(VueAxios, axios)
  .use(pinia);

app.config.globalProperties.emitter = emitter
app.use(VueMasonryPlugin)

loadMessages(csMessages);

let mounted = app.mount('#app');
locale('cs');
moment.locale('cs');
configure();