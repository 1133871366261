import { useStore as useUserStore } from "@/store/userStore";
import { IMenuItem } from "@/services/menu";

export const useNavigator = () => {
  const client = (id: number) => {
    return { name: 'client-details', params: { id: id } };
  }

  const clientEdit = (id: number) => {
    return { name: 'client-edit', params: { id: id } };
  }

  const contract = (id: number) => {
    return { name: 'contract-details', params: { id: id } }
  }

  const contractInterventions = (contractId: number) => {
    return { name: 'contract-interventions', params: { id: contractId } }
  }

  const forumThread = (id: number) => {
    return { name: 'forum-details', params: { id: id } }
  }

  const forumEdit = (id: number) => {
    return { name: 'forum-edit', params: { id: id } }
  }

  const worker = (id: number) => {
    return { name: 'worker-details', params: { id: id } }
  }

  const partner = (id: number) => {
    return { name: 'partner-details', params: { id: id } }
  }

  const product = (id: number) => {
    return { name: 'product-details', params: { id: id } }
  }

  const orderCreate = (id: number) => {
    return { name: 'order-create', params: { id: id } }
  }

  const filterMenu = (items: Array<IMenuItem>): Array<IMenuItem> => {
    var userStore = useUserStore();
    return items.filter(i => {
      return i.feature === undefined || i.feature === null || userStore.hasFeature(i.feature);
    });
  }

  return { client, clientEdit, contract, forumThread, forumEdit, contractInterventions, worker, partner, product, orderCreate, filterMenu }
}