import { ReportGeneratorClient, ReportGenerationTaskStatus, ReportGeneratorInput } from '@/services/apiClient';
import emitter, { IReportEvent, MessageType } from "@/services/emitter";
import { useStore as useAppStore } from "@/store/appStore";
import { useStore as useUserStore } from "@/store/userStore";
import DxForm, { DxColCountByScreen } from 'devextreme-vue/form';
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import { confirm } from "devextreme/ui/dialog";
import validationEngine from 'devextreme/ui/validation_engine';
import { defineComponent } from 'vue';
import ReportViewer from "./report-viewer.vue";

export default defineComponent({
  name: "ReportHandler",
  components: {
    DxForm, DxPopup, DxPosition, DxToolbarItem, DxColCountByScreen, ReportViewer
  },
  created() {
    emitter.on("Report", s => this.onReportPrepare(s));
  },
  destroyed() {
    emitter.off("Report");
  },
  setup() {
    const appStore = useAppStore();
    const userStore = useUserStore();
    return { appState: appStore.$state, appStore: appStore, userStore: userStore }
  },
  data() {
    return {
      reportType: <string>null,
      report: <IReport>null,
      filterVisible: false,
      generator: {
        uid: '',
        visible: false,
        intervalHandle: <number>null
      },
      viewer: {
        visible: false,
        uid: ''
      },
      reports: {
        RevenueMaster: () => new RevenueMasterReport(),
        Production: () => new ProductionOverviewReport(),
        ProductionSummary: () => new ProductionSummaryReport()
      }
    };
  },
  computed: {

  },
  methods: {
    async onReportPrepare(e: IReportEvent) {
      this.clearReport();

      this.reportType = e.report;
      var reportFactory = <() => IReport>(<any>this.reports)[e.report];
      if (!reportFactory)
        throw { message: 'Uknown report type', reportType: e.report };

      this.report = reportFactory();
      if (this.report.filter)
        this.filterVisible = true;
      else
        await this.onReportCreate();
    },
    async onReportCreate() {
      if (validationEngine.validateGroup('report').isValid) {
        this.clearGenerator();

        this.generator.visible = true;
        try {

          this.generator.uid = await new ReportGeneratorClient().generate(new ReportGeneratorInput({ type: this.reportType, parameters: JSON.stringify(this.report.queryParams()) }));
          this.generator.intervalHandle = setInterval(this.checkReportStatus, 500);
        }
        catch (e) {
          this.clearGenerator();
          console.error(e);
          emitter.emit('Message', { type: MessageType.Error, message: 'Při vytváření sestavy došlo k chybě' });
        }
      }
    },
    async checkReportStatus() {
      try {
        var status = await new ReportGeneratorClient().status(this.generator.uid);

        if (status.status == ReportGenerationTaskStatus.Error || status.status == ReportGenerationTaskStatus.Missing || status.status == ReportGenerationTaskStatus.Cancelled) {
          this.clearGenerator();
          emitter.emit('Message', { type: MessageType.Error, message: 'Při vytváření sestavy došlo k chybě' });
        }
        else if (status.status == ReportGenerationTaskStatus.Done) {
          this.filterVisible = false;
          this.showReport();
        }
        else if (status.status == ReportGenerationTaskStatus.NoData) {
          this.clearGenerator();
          emitter.emit('Message', { type: MessageType.Warning, message: 'Sestava neobsahuje žádná data' });
        }
      }
      catch (e) {
        this.clearGenerator();
        console.error(e);
        emitter.emit('Message', { type: MessageType.Error, message: 'Při vytváření sestavy došlo k chybě' });
      }
    },
    async onCancel() {
      if (await confirm('Zrušit vytváření sestavy?', 'Zrušit?')) {
        await new ReportGeneratorClient().cancel(this.generator.uid);
        this.clearReport();
        emitter.emit('Message', { type: MessageType.Warning, message: 'Vytváření sestavy zrušeno' });
      }
    },
    clearReport() {
      this.reportType = null;
      this.viewer.visible = false;
      this.report = null;
      this.clearGenerator();
    },
    clearGenerator() {
      this.generator.visible = false;
      this.generator.uid = null;

      if (this.generator.intervalHandle) {
        clearInterval(this.generator.intervalHandle);
        this.generator.intervalHandle = null;
      }
    },
    showReport() {
      this.viewer.uid = this.generator.uid;
      this.viewer.visible = true;
      this.clearGenerator();
    }
  }
})

interface IReport {
  title: string;
  filter: IFilter;
  queryParams(): object
}

interface IFilter {
  items: object[];
  data: object;
  height: string;
  width: string;
}

abstract class ProductionReport {
  queryParams(): object {
    return this.filter.data;
  }
  filter = {
    data: {
      yearFrom: new Date().getFullYear(),
      monthFrom: new Date().getMonth() + 1,
      yearTo: new Date().getFullYear(),
      monthTo: new Date().getMonth() + 1,
      ownRevenue: <boolean>null,
    },
    items: [
      { dataField: 'yearFrom', isRequired: true, label: { text: 'Rok od' }, editorType: "dxNumberBox", editorOptions: { min: 2000, max: new Date().getFullYear() } },
      { dataField: 'monthFrom', isRequired: true, label: { text: 'Měsíc od' }, editorType: "dxNumberBox", editorOptions: { min: 1, max: 12 } },
      { dataField: 'yearTo', isRequired: true, label: { text: 'Rok do' }, editorType: "dxNumberBox", editorOptions: { min: 2000, max: new Date().getFullYear() } },
      { dataField: 'monthTo', isRequired: true, label: { text: 'Měsíc do' }, editorType: "dxNumberBox", editorOptions: { min: 1, max: 12 } },
      { dataField: 'ownRevenue', label: { text: 'Typ produkce' }, colSpan: 2, editorType: 'dxSelectBox', editorOptions: { valueExpr: 'value', displayExpr: 'text', items: [{ value: true, text: 'Vlastní' }, { value: false, text: 'Skupina' }, { value: null, text: 'Vše' }] } },
    ],
    height: '300px',
    width: '300px'
  };
}

class RevenueMasterReport implements IReport {
  title: string = 'Provizní listina';

  filter = {
    data: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    },
    items: [
      { dataField: 'year', isRequired: true, label: { text: 'Rok' }, editorType: "dxNumberBox", editorOptions: { min: 2000, max: new Date().getFullYear() } },
      { dataField: 'month', isRequired: true, label: { text: 'Měsíc' }, editorType: "dxNumberBox", editorOptions: { min: 1, max: 12 } },
    ],
    height: '300px',
    width: '300px'
  };

  queryParams(): object {
    return {
      yearFrom: this.filter.data.year,
      monthFrom: this.filter.data.month,
      yearTo: this.filter.data.year,
      monthTo: this.filter.data.month
    };
  }

}

class ProductionSummaryReport extends ProductionReport implements IReport {
  title: string = 'Produkce sumární';
}

class ProductionOverviewReport extends ProductionReport implements IReport {
  title: string = 'Přehled produkce';
}