<template>
  <main class="layout-simple" :class="appState.screen.cssClasses + ' ' + $route.name">
    <div class="with-footer single-card flex-item-fill flex-container">
      <div class="dx-card">
        <DxScrollView width="100%">
          <div class="card-content">
            <div class="header" v-if="appState.title">
              <div class="title">{{ appState.title }}<i class="float-end fa-duotone fa-gear fa-spin" v-show="appState.isWorking || appState.isNavigating"></i></div>
            </div>
            <slot />
          </div>
        </DxScrollView>
      </div>
    </div>
  </main>
</template>

<script src="./simple.ts"></script>
<style scoped lang="scss" src="./simple.scss"></style>