import { defineComponent, PropType } from 'vue'
import { useStore } from "@/store/appStore";

export default defineComponent({
  name: "Footer",
  components: {  },
  props: {},
  setup() {
    const appStore = useStore();
    return { appState: appStore.$state, appStore: appStore }
  }
})