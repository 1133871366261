import { IInternalMessageInput, InternalMessageClient, InternalMessageInput, InternalMessageKey, InternalMessageRecipient } from '@/services/apiClient';
import { ApiHelper } from '@/services/apiClientBase';
import emitter, { ISendMessageEvent, MessageType } from "@/services/emitter";
import { useStore } from "@/store/appStore";
import { useStore as useUserStore } from "@/store/userStore";
import DxFileUploader from 'devextreme-vue/file-uploader';
import DxForm, { DxButtonItem, DxButtonOptions, DxColCountByScreen, DxItem, DxLabel, DxRequiredRule } from "devextreme-vue/form";
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import { BeforeSendEvent, UploadedEvent } from 'devextreme/ui/file_uploader';
import validationEngine from 'devextreme/ui/validation_engine';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "SendMessage",
  components: {
    DxPopup, DxPosition, DxToolbarItem,
    DxForm, DxItem, DxButtonItem, DxLabel, DxButtonOptions, DxColCountByScreen, DxRequiredRule, DxFileUploader
  },
  setup() {
    const appStore = useStore();
    const userStore = useUserStore();
    return { appState: appStore.$state, appStore, userState: userStore.$state, userStore }
  },
  created() {
    emitter.on("SendMessage", s => this.onSendMessage(s));
  },
  data() {
    return {
      messageType: '',
      objectId: 0,
      recipients: new Array<InternalMessageRecipient>(),
      message: <Message>null,
      visible: false
    };
  },
  computed: {
    uploadUrl(): string {
      return ApiHelper.buildUrl('message/upload');
    }
  },
  watch: {
    visible: {
      handler(newValue: boolean) {
        if (!newValue)
          this.message = null;
      }
    }
  },
  methods: {
    onBeforeSend(e: BeforeSendEvent) {
      e.request.withCredentials = true;
    },
    async onSendMessage(e: ISendMessageEvent) {
      this.messageType = e.type;
      this.objectId = e.objectId;
      var setup = await new InternalMessageClient().setup(this.messageType, this.objectId);

      this.recipients = setup.recipients;
      this.message = this.getMesage(e.type);

      this.visible = true;
      this.message.data.subject = setup.subject;
    },
    getMesage(code: string): Message {
      if (code == 'REVENUE')
        return new RevenueMessage();

      return new SimpleMessage();
    },
    recipientDisplayExpr: (item: InternalMessageRecipient) => {
      if (!item)
        return null;

      return `${item.name} (${item.email})`;
    },
    async onSend() {
      if (validationEngine.validateGroup('message').isValid) {
        try {
          let payload = <IInternalMessageInput>{
            key: new InternalMessageKey({ code: this.messageType, id: this.objectId }),
            recipient: this.message.data.recipient,
            subject: this.message.data.subject,
            text: this.message.data.text,
            attachments: this.message.attachments ? this.message.attachments.map(a => a) : null,
            additionalInfo: this.message.additionalData ? JSON.stringify(this.message.additionalData) : null
          };
          console.info(payload);
          await new InternalMessageClient().send(new InternalMessageInput(payload));

          this.visible = false;

          emitter.emit("Message", { type: MessageType.Success, message: "Zprávu odeslána" });
        }
        catch (e) {
          emitter.emit("Message", { type: MessageType.Error, message: "Zprávu se nepodařilo odeslat" });
          console.error(e);
        }
      }
    },
    onUploaded(e: UploadedEvent) {
      var uid = <string>JSON.parse(e.request.response);
      this.message.attachments.push(uid);
    }
  }
})

abstract class Message {
  data = {
    subject: '',
    text: '',
    recipient: ''
  };

  abstract additionalFields: any;
  abstract additionalData: any;

  attachments: string[] = [];
}

class SimpleMessage extends Message {
  additionalFields: any = null;
  additionalData: any = null;

}

class RevenueMessage extends Message {
  additionalFields: any = [
    { dataField: 'type', isRequired: true, label: { text: 'Druh reklamace' }, editorType: 'dxSelectBox', editorOptions: { items: ['Vyplacená provize', 'Nevyplacená standardní provize', 'Nevyplacená následná provize'] } },
    { dataField: 'paidOn', isRequired: true, label: { text: 'Klient zaplatil' }, editorType: 'dxDateBox', editorOptions: {} }
  ];
  additionalData = { type: '', paidOn: <Date>null };
}