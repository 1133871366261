import { ApiError } from "@/services/apiClient";
import emitter, { MessageType } from "@/services/emitter";

const setPageTitle = (titleParts: Array<string>) => {
  let title = titleParts.filter(p => p).join(" - ");
  document.title = titleParts.length > 0 ? `${process.env.VUE_APP_NAME} - ${title}` : process.env.VUE_APP_NAME;
}

const handleError = (error: any, uiMessage: string = null): void => {
  console.error(error);

  if (error.message == "Network Error")
    emitter.emit("Message", { type: MessageType.Error, message: "Aplikace je momentálně nedsotupná." });
  else
    showApiError(<ApiError>error);
};

function showApiError(error: ApiError) {
  if (error.status == 401)
    emitter.emit("Message", { type: MessageType.Error, message: "Přístup odepřen." });
  else if (error.status == 403)
    emitter.emit("Message", { type: MessageType.Error, message: "Přístup k těmto datům nemáte povolen." });
  else if (error.status == 400)
    emitter.emit("Message", { type: MessageType.Warning, message: error.message });
  else
    emitter.emit("Message", { type: MessageType.Error, message: "Došlo k chybě. Podrobnosti jsme uložili pro budoucí analýzu." });
}

export { setPageTitle, handleError };
