import { defineComponent, PropType } from 'vue'
import NavLink from "./nav-link.vue";
import { IMenuItem } from "@/services/menu";

import { useNavigator } from "@/composables/navigator";

export default defineComponent({
  name: "NavMenuLink",
  components: { NavLink },
  props: {
    menuItem: {
      type: Object as PropType<IMenuItem>,
      required: true
    }
  },
  setup() {
    const navigator = useNavigator();
    return { navigator }
  },
  computed: {
    hasChildren() {
      return this.menuItem && this.menuItem.items && this.navigator.filterMenu(this.menuItem.items).length > 0;
    }
  }
})