<template>
  
  <li class="nav-item" v-if="!hasChildren">
    <router-link class="nav-link" :to="{ name: menuItem.route }" :class="{active: menuItem.route == $route.name}">
      <i :class="`fa-duotone fa-${menuItem.icon}`" v-if="menuItem.icon"></i> {{menuItem.title}}
    </router-link>
  </li>

  <li class="nav-item dropdown" v-if="hasChildren">
    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i :class="`fa-duotone fa-${menuItem.icon}`" v-if="menuItem.icon"></i> {{menuItem.title}}
    </a>
    <ul class="dropdown-menu">
      <nav-link v-for="subItem in navigator.filterMenu(menuItem.items)" :menu-item="subItem"></nav-link>
    </ul>
  </li>
</template>

<script src="./nav-link.ts"></script>
<style scoped lang="scss" src="./nav-link.scss"></style>