import { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from 'qs';
import mitt, { MessageType } from "@/services/emitter";
import { useStore as useAppStore } from "@/store/appStore";
import { useStore as useUserStore } from "@/store/userStore";

export class ApiClientBase {
  private _appStore = useAppStore();

  protected getBaseUrl(defaultUrl: string, baseUrl?: string | undefined): string {
    return ApiHelper.rootUrl;
  }

  protected async transformOptions(options: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    options.paramsSerializer = (params: any) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
    };

    options.withCredentials = true;

    this._appStore.addApiRequest();
    return options;
  }

  protected async transformResult<T>(url: string, response: AxiosResponse, process: (response: AxiosResponse) => Promise<T>): Promise<T> {
    this._appStore.removeApiRequest();

    var exp = response.headers["x-fin-auth-exp"];
    if (exp) {
      var store = useUserStore();
      store.updateAuthentication(parseInt(exp));
    }

    if (response.status >= 200 && response.status < 300)
      return await process(response);
    else if (response.status == 401) {
      mitt.emit("Message", { type: MessageType.Error, message: "Neplatné přihlášení - přístup odepřen. Prosím přihlašte se znovu." });
      mitt.emit("Logout");
    }
    else {
      if (response.status == 403)
        mitt.emit("Message", { type: MessageType.Error, message: "Přístup k těmto datům nemáte povolen." });
      else if (response.status == 400 && response.data.message)
        mitt.emit("Message", { type: MessageType.Warning, message: response.data.message });
      else if (response.status == 400)
        mitt.emit("Message", { type: MessageType.Error, message: "Došlo k chybě při odesílání požadavku." });
      else
        mitt.emit("Message", { type: MessageType.Error, message: "Došlo k chybě při požadavku na server. Podrobnosti jsme uložili pro budoucí analýzu." });
      return await process(response);
    }
  }
}

export class ApiHelper {
  public static get rootUrl() {
    return process.env.VUE_APP_API;
  }

  public static buildUrl(url: string, parameters?: any) {
    if (url[0] == '/')
      url = url.substring(1, url.length);

    const baseUrl = `${ApiHelper.rootUrl}/intranet/${url}`;
    if (parameters) {
      const qs = Object.keys(parameters)
        .map(key => `${key}=${parameters[key]}`)
        .join('&');
      return `${baseUrl}?${qs}`;
    }
    else
      return baseUrl;
  }

}
