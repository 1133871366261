<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container-fluid">
        <div class="navbar-brand text-center" style="width: 30px">
          <i v-if="appState.isWorking || appState.isNavigating" class="fa-duotone fa-cog fa-spin"></i>
          <i v-else class="fa-duotone fa-building-user"></i>
        </div>
        <a class="navbar-brand" href="/">Intranet</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <nav-link v-for="item in navigator.filterMenu(menu)" :menu-item="item"></nav-link>

            <li class="nav-item dropdown" v-if="reports">
              <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-duotone fa-file-export"></i> Sestavy
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <li v-for="report in reports"><a class="nav-link" href="#" @click="onReport(report)">{{report.displayName}}</a></li>
              </ul>
            </li>
          </ul>

          <ul class="navbar-nav right">
            <li class="nav-item"><a class="nav-link" href="#" @click="onRefreshToken" title="Odpočet do automatického odhlášení. Kliknutím interval obnovíte."><i v-if="tokenValiditySeconds / 60 < 5" class="fa-duotone fa-beat fa-alarm-exclamation text-warning"></i> {{tokenValidity}}</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-duotone fa-user"></i> {{userState.name}}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <li><router-link class="nav-link" :to="{ name: 'user-details' }"><i class="fa-duotone fa-user-gear"></i> Uživatelský účet</router-link></li>
                <li v-if="userState.workerId"><router-link class="nav-link" :to="navigator.worker(userState.workerId)"><i class="fa-duotone fa-user-tie"></i> Konzultant</router-link></li>
                <li v-if="userState.permissions.impersonate"><a class="nav-link" href="#" @click="onImpersonate"><i class="fa-duotone fa-user-ninja"></i> Přepnout uživatele</a></li>
                <li><router-link class="nav-link" :to="{ name: 'logout' }"><i class="fa-duotone fa-right-from-bracket"></i> Odhlásit</router-link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <Impersonate :ref="impersonateRefName"></Impersonate>
</template>

<script src="./nav-bar-menu.ts"></script>
<style lang="scss" src="./nav-bar-menu.scss"></style>