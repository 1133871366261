<template>
  <DxPopup v-if="report && report.filter" v-model:visible="filterVisible" :width="report.filter.width" :height="report.filter.height" :drag-enabled="true" :resizeEnabled="true" :hide-on-outside-click="true" :show-close-button="true" :show-title="true" :shading="true" :title="report.title">
    <DxToolbarItem widget="dxButton" toolbar="bottom" location="before" :options="{ text: 'Vygenerovat', onClick: onReportCreate }" />
    <DxForm labelMode="floating" :items="report.filter.items" :form-data="report.filter.data" validation-group="report">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />
    </DxForm>
  </DxPopup>

  <DxPopup v-model:visible="generator.visible" width="270px" height="130px" :drag-enabled="false" :resizeEnabled="false" :hide-on-outside-click="false" :show-close-button="false" :show-title="false" :shading="true">
    <label class="text-center">Sestava se vytváří...</label>
    <div class="progress mt-2">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
    </div>
    <div class="text-center mt-2">
      <button type="button" class="btn btn-warning" @click="onCancel">Zrušit</button>
    </div>
  </DxPopup>

  <DxPopup :wrapper-attr="{class: 'popup-viewer'}" :fullScreen="true" v-model:visible="viewer.visible" :drag-enabled="true" :resizeEnabled="true" :hide-on-outside-click="false" :show-close-button="true" :show-title="true" :shading="false" :title="report?.title">
    <div class="flex-container"><ReportViewer :uid="viewer.uid"></ReportViewer></div>
  </DxPopup>
</template>

<script src="./report-handler.ts"></script>
<style lang="scss" src="./report-handler.scss"></style>