import { defineStore } from "pinia";
import { IntranetAuthenticationResult, IntranetFeature, IntranetPermissions } from "../services/apiClient";

export const useStore = defineStore({
  id: "userState",
  state: () => ({
    workerId: 0,
    name: <string>null,
    expiration: <number>null,
    permissions: <IntranetPermissions>null,
    features: new Array<IntranetFeature>()
  }),
  actions: {
    setAuthentication(result: IntranetAuthenticationResult) {
      this.workerId = result.workerId;
      this.name = result.name;
      this.expiration = result.expiration;
      this.permissions = result.permissions;
      this.features = result.features;
    },
    updateAuthentication(expiration: number) {
      this.expiration = expiration;
    },
    clearAuthentication() {
      this.workerId = 0;
      this.name = null;
      this.expiration = null;
      this.permissions = null;
      this.features = new Array<IntranetFeature>();
    },
    isAuthenticated(): boolean {
      return this.expiration > Date.now();
    },
    hasFeature(feature: IntranetFeature): boolean {
      if (!feature || !this.features)
        return false;
      return this.features.indexOf(feature) >= 0;
    }
  },
  persist: {
    storage: window.localStorage
  }
});