<template>
  <div class="layout-main">
    <header>
      <NavBarMenu></NavBarMenu>
      <router-view name="SubNavBar"></router-view>
    </header>

    <main class="flex-container flex-item-fill fixed">
      <slot />
    </main>

    <footer class="flex-item-fixed ">
      <AppFooter></AppFooter>
    </footer>
  </div>
</template>

<script src="./main.ts"></script>
<style lang="scss" src="./main.scss"></style>