import { defineComponent, PropType } from 'vue'
import { useStore } from "@/store/appStore";

import DxScrollView from "devextreme-vue/scroll-view";

export default defineComponent({
  name: "Simple",
  components: {
    DxScrollView
  },
  setup() {
    const appStore = useStore();
    return { appState: appStore.$state, appStore: appStore }
  }
})