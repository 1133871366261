import "devexpress-reporting/dx-webdocumentviewer";
import ko from "knockout";
import { defineComponent } from 'vue';
import { ApiHelper } from "@/services/apiClientBase";

export default defineComponent({
  name: "ReportViewer",
  data() {
    return {
      viewerRefName: "viewer",
      viewerOptions: {
        reportUrl: ko.observable(''),
        requestOptions: {
          host: `${ApiHelper.rootUrl}/`,
          invokeAction: "intranet/reportViewer"
        }
      }
    };
  },
  props: {
    uid: {
      type: String,
      required: true,
    }
  },
  mounted() {
    ko.applyBindings(this.viewerOptions, <Node>this.$refs[this.viewerRefName]);
  },
  unmounted() {
    ko.cleanNode(<Node>this.$refs[this.viewerRefName]);
  },
  watch: {
    uid: {
      immediate: true,
      handler(newValue: string, oldValue: string) {
        this.viewerOptions.reportUrl(newValue);
      }
    }
  }
})