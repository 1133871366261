import AppFooter from '@/layout/app-footer.vue';
import NavBarMenu from '@/layout/nav-bar-menu.vue';
import { useStore } from "@/store/appStore";
import DxScrollView from "devextreme-vue/scroll-view";
import { defineComponent } from 'vue';

export default defineComponent({
  name: "NavBar",
  components: {
    DxScrollView, NavBarMenu, AppFooter
  },
  setup() {
    const appStore = useStore();
    return { appState: appStore.$state, appStore: appStore }
  }
})