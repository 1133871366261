import { AuthenticationClient, ImpersonateInput } from '@/services/apiClient';
import { useStore as useUserStore } from "@/store/userStore";
import DxForm, { DxLabel, DxSimpleItem } from "devextreme-vue/form";
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import validationEngine from 'devextreme/ui/validation_engine';
import { defineComponent } from 'vue';
import { useDataStores } from "../composables/dataStores";

export default defineComponent({
  name: "Impersonate",
  components: {
    DxPopup, DxPosition, DxToolbarItem, DxSimpleItem,
    DxLabel,
    DxForm
  },
  data() {
    return {
      isVisible: false,
      validationGroup: 'impersonation',
      impersonation: {
        userId: <number>null
      }
    };
  },
  setup() {
    const userStore = useUserStore();
    const ds = useDataStores();
    return { ds, userStore }
  },
  methods: {
    open() {
      this.impersonation.userId = null;
      this.isVisible = true;
    },
    async onImpersonateSave() {
      if (validationEngine.validateGroup(this.validationGroup).isValid) {
        var result = await new AuthenticationClient().impersonate(new ImpersonateInput({ userId: this.impersonation.userId }));
        this.userStore.setAuthentication(result);
        this.isVisible = false;
        location.reload();
      }
    }
  }
})

