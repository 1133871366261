<template>

  <component :is="$route.meta.layout">
    <router-view></router-view>
  </component>

  <ReportHandler></ReportHandler>
  <SendMessage></SendMessage>

  <div class="overlay" v-show="appState.isLoading">
    <div class="container h-100">
      <div class="row align-items-center h-100">
        <div class="col-6 mx-auto">
          <div class="fa-3x">
            <i class="fas fa-circle-notch fa-spin"></i><br />
            Načítají se data. Prosím o&nbsp;pár sekund strpení.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./app.ts"></script>
<style lang="scss" src="./app.scss"></style>