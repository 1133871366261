import { CallClient, ContractClient, ListDataItem, UserClient, UserDetails, WorkerClient } from '@/services/apiClient';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';

export const useDataStores = () => {
  const productTypes = new CustomStore({
    key: 'id',
    async byKey(key) {
      return await new ContractClient().productType(key);
    },
    async load(loadOptions) {
      return await new ContractClient().productTypes(loadOptions?.searchValue, loadOptions?.skip, loadOptions?.take);
    },
  });

  const partners = new CustomStore({
    key: 'id',
    async byKey(key) {
      return await new ContractClient().partner(key);
    },
    async load(loadOptions) {
      return await new ContractClient().partners(loadOptions?.searchValue, loadOptions?.skip, loadOptions?.take);
    },
  });

  const workers = new CustomStore({
    key: 'id',
    async byKey(key) {
      return await new WorkerClient().groupListItem(key);
    },
    async load(loadOptions) {
      return await new WorkerClient().groupList(loadOptions?.searchValue);
    },
  });

  const allWorkers = new CustomStore<ListDataItem, number>({
    key: 'id',
    async byKey(key: number) {
      return await new WorkerClient().single(key);
    },
    async load(loadOptions: LoadOptions<ListDataItem>) {
      return await new WorkerClient().listAll(loadOptions?.searchValue, loadOptions.skip, loadOptions.take);
    },
  });

  const users = new CustomStore<UserDetails, number>({
    key: 'id',
    async byKey(key) {
      return await new UserClient().single(key);
    },
    async load(loadOptions) {
      return await new UserClient().query(loadOptions?.searchValue, loadOptions.skip, loadOptions.take);
    },
  });

  const callActivities = new CustomStore({
    key: 'id',
    async byKey(key) {
      return await new CallClient().activity(key);
    },
    async load(loadOptions) {
      return await new CallClient().activities(loadOptions?.searchValue, loadOptions?.skip, loadOptions?.take);
    },
  });

  //const clientContracts = new ClientContractStore({
  //  key: 'id',
  //  async byKey(key) {
  //    return await Api.get<data.IClientQueryItem>(`contract/query/${key}`, {});
  //  },
  //  async load(loadOptions) {
  //    const result = await Api.get<data.IQueryResult<data.IClientQueryItem>>('contract/query', { clientId: this.clientId, skip: loadOptions.skip, take: loadOptions.take, query: loadOptions.filter && loadOptions.filter.length > 0 ? loadOptions.filter[0].filterValue : null });
  //    if (loadOptions.requireTotalCount)
  //      return result;
  //    else
  //      return result.data;
  //  },
  //});

  const parseFilter = (filter: Array<any>, field: string): any => {
    let ix = filter.indexOf(field);
    let len = filter.length;

    if (ix == 0 && len == 3)
      return filter[2];
    else {
      let result = null;
      filter.filter(a => Array.isArray(a) && (typeof a) != 'string').forEach(i => {
        let subResult = parseFilter(<Array<any>>i, field);
        if (subResult)
          result = subResult;
      });

      return result;
    }
  }

  return { productTypes, partners, workers, allWorkers, parseFilter, callActivities, users }
}