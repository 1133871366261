import Impersonate from '@/components/impersonate.vue';
import { useNavigator } from "@/composables/navigator";
import { AuthenticationClient, ReportGeneratorClient, ReportInfo } from '@/services/apiClient';
import emitter from "@/services/emitter";
import { MainMenu } from "@/services/menu";
import { useStore as useAppStore } from "@/store/appStore";
import { useStore as useUserStore } from "@/store/userStore";
import moment from 'moment';
import { defineComponent } from 'vue';
import NavLink from "./components/nav-link.vue";

export default defineComponent({
  name: "HeaderToolbar",
  components: {
    Impersonate, NavLink
  },
  data() {
    return {
      menu: MainMenu,
      now: Date.now(),
      reports: <ReportInfo[]>null,
      impersonateRefName: 'impersonate'
    };
  },
  created() {
    var self = this
    setInterval(() => {
      self.now = Date.now()
    }, 1000);
  },
  setup() {
    const appStore = useAppStore();
    const userStore = useUserStore();
    const navigator = useNavigator();
    return { appState: appStore.$state, userState: userStore.$state, appStore, userStore, navigator }
  },
  async mounted() {
    this.reports = await new ReportGeneratorClient().globalReports();
  },
  methods: {
    onImpersonate() {
      (<any>this.$refs[this.impersonateRefName]).open();
    },
    async onRefreshToken() {
      await new AuthenticationClient().refresh();
    },
    onReport(report: ReportInfo) {
      emitter.emit('Report', { report: report.type, params: {} });
    }
  },
  watch: {
    tokenValiditySeconds(val) {
      if (val <= 0 && this.userState.expiration)
        this.$router.push({ name: 'logout' });
    },
  },
  computed: {
    tokenValiditySeconds(): number {
      return this.userState.expiration ? (this.userState.expiration - this.now) / 1000 : 0;
    },
    tokenValidity(): string {
      var duration = moment.duration(this.tokenValiditySeconds, 'seconds');
      var hours = duration.hours() + 24 * duration.days();
      if (hours > 0)
        return `${hours.toLocaleString().padStart(2, '0')}:${duration.minutes().toLocaleString().padStart(2, '0')}:${duration.seconds().toLocaleString().padStart(2, '0')}`;
      else
        return `${duration.minutes().toLocaleString().padStart(2, '0')}:${duration.seconds().toLocaleString().padStart(2, '0')}`;
    }
  }
})

