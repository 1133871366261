<template>
  <DxPopup v-model:visible="isVisible" :drag-enabled="true" :resizeEnabled="true" :hide-on-outside-click="true" :show-close-button="true" :show-title="true" :shading="true" title="Přepnout na jiného uživatele" height="250px" width="400px">
    <DxToolbarItem widget="dxButton" toolbar="bottom" location="before" :options="{ text: 'Přepnout', onClick: onImpersonateSave }" />
    <div>

      <DxForm labelMode="floating" v-model:form-data="impersonation" :validation-group="validationGroup">
        <DxSimpleItem data-field="userId" :isRequired="true" :editor-options="{ valueExpr: 'id', displayExpr: 'name', dataSource: ds.users, searchEnabled: true }" editorType="dxSelectBox">
          <DxLabel text="Uživatel" />
        </DxSimpleItem>
        <DxSimpleItem>
          <p class="text-danger">Po přepnutí se všechna oprávnění nastaví podle nového uživatele. Všechny provedené změny budou zalogovány pod novým uživatelem.</p>
        </DxSimpleItem>
      </DxForm>
    </div>
  </DxPopup>
</template>

<script src="./impersonate.ts"></script>
<style lang="scss" src="./impersonate.scss"></style>