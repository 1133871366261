import { defineStore } from "pinia";

export const useStore = defineStore({
  id: "appState",
  state: () => ({
    apiRequests: 0,
    isWorking: false,
    isNavigating: false,
    isLoading: false,
    screen: <IScreen>null
  }),
  actions: {
    addApiRequest() {
      this.apiRequests++;
      this.isWorking = this.apiRequests > 0;
    },
    removeApiRequest() {
      this.apiRequests--;
      if (this.apiRequests < 0)
        this.apiRequests = 0;
      this.isWorking = this.apiRequests > 0;
    },
    setLoading(value: boolean) {
      this.isLoading = value;
    },
    setNavigating(value: boolean) {
      this.isNavigating = value;
    },    
    setScreen(screen: IScreen) {
      this.screen = screen;
    }
  }
});

export interface IScreen {
  isLarge: boolean;
  isMedium: boolean;
  isSmall: boolean;
  isXSmall: boolean;
  cssClasses: string[];
}