const Breakpoints = {
  XSmall: "(max-width: 639px)",
  Small: "(min-width: 640px)",
  Medium: "(min-width: 1280px)",
  Large: "(min-width: 1920px)"
};

let handlers: any[] = [];

const xSmallMedia = window.matchMedia(Breakpoints.XSmall);
const smallMedia = window.matchMedia(Breakpoints.Small);
const mediumMedia = window.matchMedia(Breakpoints.Medium);
const largeMedia = window.matchMedia(Breakpoints.Large);

[xSmallMedia, smallMedia, mediumMedia, largeMedia].forEach(media => {
  media.addListener(() => {
    handlers.forEach(handler => handler());
  });
});

export const sizes = (): { [id: string]: boolean } => {
  return {
    "screen-x-small": xSmallMedia.matches && !smallMedia.matches,
    "screen-small": smallMedia.matches && !mediumMedia.matches,
    "screen-medium": mediumMedia.matches && !largeMedia.matches,
    "screen-large": largeMedia.matches
  };
};

export const subscribe = (handler: any) => handlers.push(handler);

export const unsubscribe = (handler: any) => {
  handlers = handlers.filter(item => item !== handler);
};
