import SelectBox from "devextreme/ui/select_box";
import TextBox from "devextreme/ui/text_box";
import Form from "devextreme/ui/form";

export default function configure() {
  SelectBox.defaultOptions({
    options: {
      labelMode: 'floating'
    }
  });

  TextBox.defaultOptions({
    options: {
      labelMode: 'floating'
    }
  });

  //Form.defaultOptions({
  //  options: {
  //    labelMode: 'floating'
  //  }
  //});
}