import emitter, { IMessageEvent, MessageType } from "@/services/emitter";
import { useStore as useAppStore } from "@/store/appStore";
import { useStore as useUserStore } from "@/store/userStore";
import { sizes, subscribe, unsubscribe } from "@/utils/media-query";
import ReportHandler from "@/views/reports/report-handler.vue";
import SendMessage from "@/components/send-message.vue";
import notify from 'devextreme/ui/notify';
import { defineComponent } from 'vue';
import { RouteLocation, RouteLocationRaw, useRoute } from 'vue-router';

export default defineComponent({
  name: "App",
  components: { ReportHandler, SendMessage },
  setup() {
    const route = useRoute()
    const appStore = useAppStore();
    const userStore = useUserStore();
    return { appState: appStore.$state, appStore: appStore, userStore: userStore, route }
  },
  created() {
    subscribe(this.onScreenChanged);
    this.onScreenChanged();
    emitter.on("Message", s => this.onMessage(s));
    emitter.on("Logout", () => this.onApiAuth());
  },
  beforeUnmount() {
    unsubscribe(this.onScreenChanged);
  },
  data() {
    return {
      toastVisible: false,
      toastText: '',
      toastType: ''
    };
  },
  watch: {
    $route(to: RouteLocation, from: RouteLocation) {
      if (from && from.name)
        document.body.classList.remove(<string>from.name);
      if (from && from.meta.class)
        document.body.classList.remove(<string>from.meta.class);
      if (to && to.name)
        document.body.classList.add(<string>to.name);
      if (to && to.meta.class)
        document.body.classList.add(<string>to.meta.class);
    }
  },
  methods: {
    onMessage(e: IMessageEvent) {
      if (e.type == MessageType.Error)
        console.log("ERROR", e.message);
      notify({ message: e.message, type: e.type, displayTime: 5000, hoverStateEnabled: true, closeOnClick: true }, { position: 'bottom center', direction: 'up-push' });
    },
    onApiAuth() {
      this.userStore.clearAuthentication();
      this.$router.push({ name: 'login' });
    },
    onScreenChanged() {
      let screenSizes = sizes();
      let screen = {
        isXSmall: screenSizes["screen-x-small"],
        isSmall: screenSizes["screen-small"],
        isMedium: screenSizes["screen-medium"],
        isLarge: screenSizes["screen-large"],
        cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
      };
      this.appStore.setScreen(screen);
      document.body.classList.remove("screen-x-small", "screen-small", "screen-medium", "screen-large");
      screen.cssClasses.forEach(c => document.body.classList.add(c));
    }
  }
})