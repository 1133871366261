import defaultLayout from "@/layout/main.vue";
import simpleLayout from "@/layout/simple.vue";
import { useStore as useAppStore } from "@/store/appStore";
import { useStore as useUserStore } from "@/store/userStore";
import { setPageTitle } from '@/utils/utils';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'home', component: () => import('@/views/home/home.vue'), meta: { title: 'Hlavní stránka', layout: defaultLayout, scrollable: true } },
  { path: '/klienti', name: 'client-list', component: () => import('@/views/clients/list.vue'), meta: { title: 'Klienti', layout: defaultLayout, class: 'layout-light' } },
  {
    path: '/klienti/:id',
    components: {
      default: () => import('@/layout/details-master.vue'),
      SubNavBar: () => import('@/views/clients/menu.vue'),
    },
    meta: { title: 'Klient', layout: defaultLayout, icon: 'users', displayProperty: 'name' },
    children: [
      { name: 'client-details', path: '', component: () => import('@/views/clients/details.vue'), meta: { title: 'Detaily' } },
      { name: 'client-contracts', path: 'smlouvy', component: () => import('@/views/clients/contracts.vue'), meta: { title: 'Smlouvy', class: 'layout-light' } },
      { name: 'client-files', path: 'soubory', component: () => import('@/views/clients/files.vue'), meta: { title: 'Soubory', class: 'layout-light' } },
      { name: 'client-remarks', path: 'poznamky', component: () => import('@/views/clients/remarks.vue'), meta: { title: 'Poznámky' } },
      { name: 'client-calls', path: 'callcentrum', component: () => import('@/views/clients/client-calls.vue'), meta: { title: 'Callcentrum', class: 'layout-light' } }
    ],
  },

  { path: '/callcentrum', name: 'call-list', component: () => import('@/views/clients/list-calls.vue'), meta: { title: 'Callcentrum', layout: defaultLayout } },

  { path: '/smlouvy', name: 'contract-list', component: () => import('@/views/contracts/list.vue'), meta: { title: 'Smlouvy', layout: defaultLayout, class: 'layout-light' } },
  {
    path: '/smlouvy/:id',
    name: 'contract-details-master',
    components: {
      default: () => import('@/layout/details-master.vue'),
      SubNavBar: () => import('@/views/contracts/menu.vue'),
    },
    meta: { title: 'Smlouva', layout: defaultLayout, displayProperty: 'id' },
    children: [
      { name: 'contract-details', path: '', component: () => import('@/views/contracts/details.vue'), meta: { title: 'Detaily' } },
      { name: 'contract-files', path: 'soubory', component: () => import('@/views/contracts/files.vue'), meta: { title: 'Soubory', class: 'layout-light' } },
      { name: 'contract-remarks', path: 'poznamky', component: () => import('@/views/contracts/remarks.vue'), meta: { title: 'Poznámky' } },
      { name: 'contract-interventions', path: 'intervence', component: () => import('@/views/contracts/interventions.vue'), meta: { title: 'Intervence', class: 'layout-light' } },
      { name: 'contract-calls', path: 'callcentrum', component: () => import('@/views/contracts/calls.vue'), meta: { title: 'Callcentrum', class: 'layout-light' } }
    ],
  },

  { path: '/produkce/prehled', name: 'production-overview', component: () => import('@/views/production/overview.vue'), meta: { title: 'Přehled produkce', layout: defaultLayout } },

  { path: '/kalendar', name: 'events-calendar', component: () => import('@/views/events/calendar.vue'), meta: { title: 'Kalendář', layout: defaultLayout } },

  {
    path: '/intervence',
    name: 'intervention-list',
    components: {
      default: () => import('@/layout/details-master.vue'),
      SubNavBar: () => import('@/views/interventions/list-menu.vue'),
    },
    meta: { title: 'Intervence', layout: defaultLayout },
    children: [
      { name: 'intervention-list-external', path: 'externi', component: () => import('@/views/interventions/list-external.vue'), meta: { title: 'Externí', class: 'layout-light' } },
      { name: 'intervention-list-internal', path: 'interni', component: () => import('@/views/interventions/list-internal.vue'), meta: { title: 'Interní', class: 'layout-light' } },
      { name: 'intervention-list-debts', path: 'dluhy', component: () => import('@/views/interventions/list-debts.vue'), meta: { title: 'Dluhy', class: 'layout-light' } },
      { name: 'intervention-list-incomplete', path: 'nekompletni-smlouvy', component: () => import('@/views/interventions/list-incomplete.vue'), meta: { title: 'Nekompletní smlouvy', class: 'layout-light' } },
      { name: 'intervention-list-warning', path: 'varovani', component: () => import('@/views/interventions/list-warning.vue'), meta: { title: 'Varování', class: 'layout-light' } },
      { name: 'intervention-list-mismatched', path: 'nesparovane-provize', component: () => import('@/views/interventions/list-mismatched.vue'), meta: { title: 'Nespárované provize', class: 'layout-light' } }
    ],
  },

  { path: '/partneri', name: 'product-search', component: () => import('@/views/products/search.vue'), meta: { title: 'Partneři', layout: defaultLayout } },

  {
    path: '/partner/:id',
    name: 'partner-details-master',
    components: {
      default: () => import('@/layout/details-master.vue'),
      SubNavBar: () => import('@/views/products/partner-menu.vue'),
    },
    meta: { title: 'Partner', layout: defaultLayout, icon: 'buildings', displayProperty: 'code' },
    children: [
      { name: 'partner-details', path: '', component: () => import('@/views/products/partner-details.vue'), meta: { title: 'Detaily' } },
      { name: 'partner-files', path: 'soubory', component: () => import('@/views/products/partner-files.vue'), meta: { title: 'Soubory', class: 'layout-light' } },
    ],
  },

  {
    path: '/produkt/:id',
    name: 'product-details-master',
    components: {
      default: () => import('@/layout/details-master.vue'),
      SubNavBar: () => import('@/views/products/product-menu.vue'),
    },
    meta: { title: 'Produkt', layout: defaultLayout, icon: 'boxes-stacked', displayProperty: 'code' },
    children: [
      { name: 'product-details', path: '', component: () => import('@/views/products/product-details.vue'), meta: { title: 'Detaily' } },
      { name: 'product-files', path: 'soubory', component: () => import('@/views/products/product-files.vue'), meta: { title: 'Soubory', class: 'layout-light' } },
    ],
  },

  //{ path: '/novinky', name: 'news-list', component: Home, meta: { title: 'Hlavní stránka', layout: defaultLayout, scrollable: true } },
  { path: '/novinky/:id', name: 'news-details', component: () => import('@/views/news/news-details.vue'), meta: { title: 'Novinka', layout: defaultLayout } },

  { path: '/forum', name: 'forum-list', component: () => import('@/views/forum/list.vue'), meta: { title: 'Forum', layout: defaultLayout } },
  { path: '/forum/:id', name: 'forum-details', component: () => import('@/views/forum/details.vue'), meta: { title: 'Forum', layout: defaultLayout, displayProperty: 'title' } },

  {
    path: '/konzultanti/:id',

    components: {
      default: () => import('@/layout/details-master.vue'),
      SubNavBar: () => import('@/views/workers/menu.vue'),
    },
    meta: { title: 'Konzultant', layout: defaultLayout, icon: 'users', displayProperty: 'name' },
    children: [
      { name: 'worker-details', path: '', component: () => import('@/views/workers/details.vue'), meta: { title: 'Detaily' } },
      { name: 'worker-dates', path: 'administrativni-povinnosti', component: () => import('@/views/workers/dates.vue'), meta: { title: 'Administrativní povinnosti' } },
      { name: 'worker-files', path: 'soubory', component: () => import('@/views/workers/files.vue'), meta: { title: 'Soubory', class: 'layout-light' } },
      { name: 'worker-group', path: 'skupina', component: () => import('@/views/workers/group.vue'), meta: { title: 'Skupina', class: 'layout-light' } },
    ],
  },

  {
    path: '/udalosti',
    name: 'event-list',
    components: {
      default: () => import('@/layout/details-master.vue'),
      SubNavBar: () => import('@/views/events/list-menu.vue'),
    },
    meta: { title: 'Intervence', layout: defaultLayout, class: 'layout-light' },
    children: [
      { name: 'event-list-common', path: 'obecne', component: () => import('@/views/events/list-common.vue'), meta: { title: 'Obecné' } },
      { name: 'event-list-birthday', path: 'narozeniny', component: () => import('@/views/events/list-birthday.vue'), meta: { title: 'Narozeniny' } }
    ],
  },

  { path: '/objednavka', name: 'order-stores', component: () => import('@/views/orders/stores.vue'), meta: { title: 'Sklady', layout: defaultLayout } },
  { path: '/objednavka/:id', name: 'order-create', component: () => import('@/views/orders/create.vue'), meta: { title: 'Objednávka', layout: defaultLayout, displayProperty: 'name' } },

  { path: '/uzivatel', name: 'user-details', component: () => import('@/views/users/details.vue'), meta: { title: 'Uživatel', layout: defaultLayout } },

  { path: '/prihlaseni', name: 'login', component: () => import('@/views/security/login.vue'), meta: { title: 'Přihlášení', layout: simpleLayout, authenticated: false } },
  { path: '/reset-hesla', name: 'password-reset', component: () => import('@/views/security/password-reset.vue'), meta: { title: 'Reset hesla', layout: simpleLayout, authenticated: false } },
  { path: '/odhlasit', name: 'logout', component: () => import('@/views/security/logout.vue'), meta: { title: 'Odhlásit', layout: simpleLayout } }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  useAppStore().setNavigating(true);
  if (to.matched.some(r => r.meta.authenticated ?? true)) {
    const store = useUserStore();
    if (store.isAuthenticated())
      next();
    else {
      console.info("No auth token or token expired.");
      next({ name: 'login' });
    }
  }
  else
    next();
})

router.afterEach((to, from) => {
  let path = new Array<string>();
  to.matched.forEach(route => {
    path.push(<string>route.meta.title);
    if (route.meta.displayProperty)
      path.push(<string>to.params[<string>route.meta.displayProperty]);
  });
  setPageTitle(path);
  useAppStore().setNavigating(false);
})

export default router;