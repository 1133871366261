import { ClientBasicData, ContractInfo, IntranetFeature } from "./apiClient"


export interface IMenuItem<T = any> {
  title: string,
  route: string,
  icon: string,
  feature: IntranetFeature,
  items: IMenuItem<T>[],
  isEnabled?: (obj: T) => boolean;
  badge?: (obj: T) => IMenuBadge;
}

export interface IMenuButton {
  title: string,
  icon: string,
  onClick: Function
}

export interface IMenuBadge {
  text: string;
  type: string;
}

export const MainMenu = <IMenuItem[]>[
  {
    route: 'home',
    title: 'Hlavní stránka',
    icon: 'house'
  },
  {
    route: 'client-list',
    title: 'Klienti',
    icon: 'users',
    items: [
      {
        route: 'client-list',
        title: 'Seznam',
        icon: 'users',
        feature: IntranetFeature.CallCenter
      },
      {
        route: 'call-list',
        title: 'Callcentrum',
        icon: 'phone-office',
        feature: IntranetFeature.CallCenter
      },
    ]
  },
  {
    title: 'Smlouvy',
    icon: 'file-contract',
    items: [
      {
        route: 'contract-list',
        title: 'Seznam',
        icon: 'file-contract'
      },
      {
        title: 'Intervence',
        icon: 'file-contract',
        route: 'intervention-list-external'
      }
    ]
  },
  {
    title: 'Kalendář',
    icon: 'calendar-days',
    route: 'events-calendar',
  },
  {
    title: 'Partneři',
    icon: 'buildings',
    route: 'product-search',
  },
  {
    title: 'Produkce',
    icon: 'chart-pie',
    items: [
      {
        route: 'production-overview',
        title: 'Přehled',
        icon: 'chart-pie'
      }
    ],
    feature: IntranetFeature.Revenues
  },
  {
    title: 'Forum',
    icon: 'comments-question-check',
    route: 'forum-list',
    feature: IntranetFeature.Forum
  },
  {
    title: 'Objednávky',
    icon: 'box-open-full',
    route: 'order-stores',
    feature: IntranetFeature.Store
  }
]

export const ClientMenu = <IMenuItem<ClientBasicData>[]>[
  {
    title: 'Detaily',
    route: 'client-details',
    icon: 'user'
  },
  {
    title: 'Smlouvy',
    route: 'client-contracts',
    icon: 'file-contract',
    isEnabled: c => c.contractCount > 0
  },
  {
    title: 'Soubory',
    route: 'client-files',
    icon: 'files'
  },
  {
    title: 'Poznámky',
    route: 'client-remarks',
    icon: 'notes'
  },
  {
    route: 'client-calls',
    title: 'Callcentrum',
    icon: 'phone-office',
    feature: IntranetFeature.CallCenter,
    isEnabled: c => c.callCenterCount > 0
  }
]

export const WorkerMenu = <IMenuItem[]>[
  {
    title: 'Detaily',
    route: 'worker-details',
    icon: 'user-tie'
  },
  {
    title: 'Administrativní povinnosti',
    route: 'worker-dates',
    icon: 'envelopes-bulk'
  },
  {
    title: 'Soubory',
    route: 'worker-files',
    icon: 'files'
  },
  {
    title: 'Skupina',
    route: 'worker-group',
    icon: 'people-group'
  }
]

export const ContractMenu = <IMenuItem<ContractInfo>[]>[
  {
    title: 'Detaily',
    route: 'contract-details',
    icon: 'user'
  },
  {
    title: 'Soubory',
    route: 'contract-files',
    icon: 'files'
  },
  {
    title: 'Poznámky',
    route: 'contract-remarks',
    icon: 'notes'
  },
  {
    title: 'Intervence',
    route: 'contract-interventions',
    icon: 'triangle-exclamation',
    isEnabled: c => c.interventionCount > 0,
    badge: c => c.interventionCount > 0 ? { text: c.interventionCount.toLocaleString(), type: 'danger' } : null
  },
  {
    route: 'contract-calls',
    title: 'Callcentrum',
    icon: 'phone-office',
    feature: IntranetFeature.CallCenter,
    isEnabled: c => c.callCenterCount > 0
  }
]

export const InterventionMenu = <IMenuItem[]>[
  {
    title: 'Externí',
    route: 'intervention-list-external',
  },
  {
    title: 'Interní',
    route: 'intervention-list-internal',
  },
  {
    title: 'Dluhy',
    route: 'intervention-list-debts',
  },
  {
    title: 'Nekompletní smlouvy',
    route: 'intervention-list-incomplete',
  },
  {
    title: 'Varování na smlouvách',
    route: 'intervention-list-warning',
  },
  {
    title: 'Nespárované provize',
    route: 'intervention-list-mismatched',
  }
]

export const PartnerMenu = <IMenuItem[]>[
  {
    title: 'Detaily',
    route: 'partner-details',
    icon: 'buildings'
  },
  {
    title: 'Soubory',
    route: 'partner-files',
    icon: 'files'
  }
]

export const ProductMenu = <IMenuItem[]>[
  {
    title: 'Detaily',
    route: 'product-details',
    icon: 'boxes-stacked'
  },
  {
    title: 'Soubory',
    route: 'product-files',
    icon: 'files'
  }
]

export const EventMenu = <IMenuItem[]>[
  {
    title: 'Obecné',
    route: 'event-list-common',
  },
  {
    title: 'Narozeniny',
    route: 'event-list-birthday',
  }
]