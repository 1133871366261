import mitt from 'mitt';

export type EmitterEvents = {
  Message: IMessageEvent,
  Logout: void,
  Report: IReportEvent
  SendMessage: ISendMessageEvent,
  ClientUpdated: void
};

export interface IMessageEvent {
  type: MessageType,
  message: string
};

export interface ISendMessageEvent {
  type: string,
  objectId: number
};


export interface IReportEvent {
  report: string,
  params: object
};

export enum MessageType {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}

export default mitt<EmitterEvents>()